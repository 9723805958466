import React from "react"



class Navbar extends React.Component {

  render() {
    return (
    <header className = "navbar">
      <img style={{height:40, marginLeft:20, marginTop:10,}} src={require("../images/logo_inverse.png")}/>

      <div style = {{display: 'flex', alignItems : 'center', justifyContent:"flex-end", flex:1}}>

        <div className="balance">
          RÉPARTITION DES ÉNIGMES
          <div className="balance-line">
            {
              this.props.count.map((count,key)=>{
              return(
              <>
                <p style = {{marginRight : 10, fontWeight : 600}}>{count.numb}</p>
                <img src={require("../images/"+count.name+"_outline_dark.svg")} />
              </>
              )
            })
            }
          </div>
        </div>

        <p className="distance">
          Distance approximative de jeu<br/>
          <span style={{fontSize:"2em", fontWeight:600}} id="distance-value">
            {this.props.distance} KM
          </span>
        </p>
      </div>
    </header>
    )
  }
}

export default Navbar
