/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import SEO from "./seo"
import "../css/layout.scss"
import "../css/app.scss"
import Navbar from "./navbar"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <>

      <div className='row' id="main">
        <div className='col' id="main-left">
          <div>
            {/*<Header siteTitle={data.site.siteMetadata.title}/>*/}
            <SEO title={data.site.siteMetadata.title}/>
            <main>
              {children}
            </main>
          </div>

          {/**
          <footer>
            © {new Date().getFullYear()}
          </footer>
          */}
        </div>
        <div className='col' id="main-right">
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
